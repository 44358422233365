import { template as template_5b1fae6498e540dd9317386b98aa03e5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5b1fae6498e540dd9317386b98aa03e5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
