import { template as template_c5f7deaecdfe4cdbab3aab3180ab0776 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c5f7deaecdfe4cdbab3aab3180ab0776(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
