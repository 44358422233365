import { template as template_4ea5384108fd4aa49450c23fe65e7824 } from "@ember/template-compiler";
const WelcomeHeader = template_4ea5384108fd4aa49450c23fe65e7824(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
